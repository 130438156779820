import React from "react"
import { FaEnvelope, FaPhone } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { SecMainHead, CardMainHead } from "../styles/styledComponent"

const ContactPage = () => {
  return (
    <Layout>
      <SEO
        title="Rishav Pandey | Contact Me"
        description="Rishav Pandey Portfolio"
      />
      <div className="contact-page">
        <div className="sec-type-2">
          <div className="container">
            <div className="main-wrapper">
              <div className="sec-heading">
                <SecMainHead>Contact Me</SecMainHead>
              </div>
              <div className="sec-desc">
                <h2>I'd Love To Hear From You.</h2>
                <p>For any query or communication feel free to contact me.</p>
              </div>
              <div
                className="card-grid"
                style={{ textAlign: "center", marginTop: "3rem" }}
              >
                <div className="grid-view md-2-col-grid">
                  <div className="card">
                    <div className="card-icon">
                      <FaEnvelope className="fa-icon fa-2x" />
                    </div>
                    <div className="card-title">
                      <CardMainHead
                        rightLarge="37%"
                        rightMedium="39%"
                        rightSmall="37%"
                      >
                        Email me at
                      </CardMainHead>
                    </div>
                    <div className="card-desc">
                      <p>
                        <a href="mailto:correspond.rishav@gmail.com">
                          correspond.rishav@gmail.com
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-icon">
                      <FaPhone className="fa-icon fa-2x" />
                    </div>
                    <div className="card-title">
                      <CardMainHead
                        rightLarge="35%"
                        rightMedium="36%"
                        rightSmall="36%"
                      >
                        Call me at
                      </CardMainHead>
                    </div>
                    <div className="card-desc">
                      <p>
                        <a href="tel:+91-9771578320">+91-9771578320</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
